import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE from "../../assets/images/setup/setup-trackcode-only.png"
import SETUP_BIGCOMMERCE_MENUS from "../../assets/images/setup/setup-bigcommerce-menus.png"
import SETUP_BIGCOMMERCE_PASTE from "../../assets/images/setup/setup-bigcommerce-paste.png"
import SETUP_BIGCOMMERCE_WARNING from "../../assets/images/setup/setup-bigcommerce-warning.png"

const metadata = {
  title: "Connect BigCommerce to ReferralCandy",
}

const BigcommerceSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for BigCommerce</h1>
        <p className={fontType.b1}>Step 1: Sign up for ReferralCandy</p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#bigcommerceoauth">
              sign up page
            </a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Click the blue "Install Bigcommerce App!" button to authorize the
            ReferralCandy app to access to your Bigcommerce store.
          </li>
          <li>
            Complete the rest of the setup wizard. At the integration step, note
            the tracking code. You will need it to complete the integration in
            the next part.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE} alt="Tracking Code Only" />
        </ol>
        <p className={fontType.b1}>Step 2: Insert our tracking code</p>
        <ol type="1">
          <li>Log into your Bigcommerce Control Panel.</li>
          <li>In the left sidebar menu, click "Advanced Settings".</li>
          <li>
            Click "Affiliate Conversion Tracking" in the navigation menu that
            appears.
          </li>
          <br />
          <br />
          <img src={SETUP_BIGCOMMERCE_MENUS} alt="Setup Bigcommerce menus" />
          <li>
            In the Conversion Tracking Code panel, copy and paste the
            ReferralCandy tracking code from Step 3 into the input box.
          </li>
          <li>
            Click the "Save" button on the bottom right of the page to save the
            changes.
          </li>
          <br />
          <br />
          <img src={SETUP_BIGCOMMERCE_PASTE} alt="Setup Bigcommerce paste" />
          <li>
            A pop-up alert window will appear that says 'Your Conversion
            Tracking Code Is Invalid'. You can ignore this warning. Click the
            'OK' button to save the changes, and you&rsquo;re done!
            <br />
            <br />
            <img
              src={SETUP_BIGCOMMERCE_WARNING}
              alt="Setup Bigcommerce warning"
            />
          </li>
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default BigcommerceSetup
